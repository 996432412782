import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import type { Event } from '@app-tournament/types';
import { loadStripe, StripeCardElementOptions } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements, CardElementProps } from '@stripe/react-stripe-js';
import fetchEvent from '../api/fetch-event';
import createPaymentIntent from '../api/create-payment-intent';
import confirmPayment from '../api/confirm-payment';
import Skeleton from 'react-loading-skeleton';
import { Helmet } from 'react-helmet';
import 'react-loading-skeleton/dist/skeleton.css';

// const stripePromise = loadStripe('pk_test_51PPMkz00DLsSVsE1gd79iUVB9RwA2sxJJ4HaCLA6XH5L0lwl0nFPHnHFQdqqKDj2rauRjQ78PdFBOLhUnT4k9cTw00zoF0Wdym', {locale: 'fr-FR'}); 
const stripePromise = loadStripe('pk_live_51PPMkz00DLsSVsE1GHAHhu9rL05KJqlNkPdb0R6VySCord7zPMCM7UzsmvheGM4tYwRq39FKABZrPydQUSSTfj4D00mXUTXEdB', {locale: 'fr-FR'}); 


const PaymentForm: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>(); // Extract eventId from URL
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>('');
  const [participantName, setParticipantName] = useState<string>('');
  const [currentEvent, setCurrentEvent] = useState<Event | undefined>();
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true); // New loading state

  const stripe = useStripe();
  const elements = useElements();
  const hasFetchedEvent = useRef(false); // Ref to track if the event has been fetched

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        if (!eventId || hasFetchedEvent.current) return;
        setLoading(true); // Set loading to true before fetching the event
        hasFetchedEvent.current = true; // Mark the event as fetched
  
        const event = await fetchEvent({ eventId });
        if (!event) {
          throw new Error('Event not found.');
        }
        setCurrentEvent(event);
      } catch (error) {
        console.error(error);
        setErrorMessage('Failed to fetch event details.');
        alert('Event not found or an error occurred while fetching the event.');
      } finally {
        setLoading(false); // Set loading to false once the fetch is complete
      }
    };
  
    if (eventId) {
      fetchEventDetails();
    }
  
    return () => {
      hasFetchedEvent.current = false; // Reset the event fetch flag on component unmount
      setLoading(true); // Reset loading state on component unmount
    };
  }, [eventId]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    try {
      if (!eventId) return;

      const paymentIntent = await createPaymentIntent({ eventId, participantName });
      setClientSecret(paymentIntent.clientSecret);

      const result = await stripe.confirmCardPayment(paymentIntent.clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement)!,
          billing_details: {
            email: email,
          },
        },
      });

      if (result.error) {
        setErrorMessage(result.error.message || 'Payment failed');
        navigate('/payment-error');
      } else {
        if (result.paymentIntent?.status === 'requires_capture') {
          setErrorMessage(null);

          // Confirm the payment and update participant status
          await confirmPayment({
            eventId,
            paymentIntentId: paymentIntent.paymentIntentId,
            participantName,
            email
          });

          navigate('/payment-success');
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage('An unexpected error occurred.');
      }
      navigate('/payment-error');
    }

    setIsProcessing(false);
  };

  return (
    <div>
=      <Helmet>
      <meta property="og:title" content="Activy Event" />
      <meta property="og:description" content="Deposez votre caution pour participer." />
      <meta property="og:image" content={currentEvent?.imageUrl} />
      <meta property="og:url" content={`https://activytime.com/payment/${eventId}`} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Activy Event" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Activy Event" />
      <meta name="twitter:description" content="Deposez votre caution pour participer." />
      <meta name="twitter:image" content={currentEvent?.imageUrl} />
    </Helmet>
    <header className="bg-black shadow-lg">
    <div className="container mx-auto py-4 flex items-center justify-between">
      <div className="flex items-center">
        <img src="/logo192.png" alt="Activy Event Logo" className="w-12 h-12 mr-4" />
        <h2 className="text-2xl font-bold text-white">Activy Event</h2>          </div>
    </div>
  </header>
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      {loading ? (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-6xl p-8 bg-white rounded-lg shadow-lg">
          <div className="flex flex-col items-center p-4">
            <Skeleton height={200} width={200} className="mb-6 rounded-lg" />
            <Skeleton height={30} width={300} className="mb-6" />
            <Skeleton count={3} height={20} width={300} className="mb-3" />
            <Skeleton height={20} width={300} className="mt-6" />
          </div>
          <div className="flex flex-col items-center p-4">
            <Skeleton height={50} width={300} className="mb-4" />
            <Skeleton height={50} width={300} className="mb-4" />
            <Skeleton height={50} width={300} className="mb-4" />
            <Skeleton height={50} width={300} />
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-6xl p-8 bg-white rounded-lg shadow-lg">
          <div className="flex flex-col items-center p-4">
            <img src={currentEvent?.imageUrl} alt="Tournament" className="w-full max-w-xs mb-6 rounded-lg" />
            <h2 className="mb-6 text-2xl font-bold text-gray-800 text-center">{currentEvent?.eventName}</h2>
            <div className="mb-3 text-lg text-gray-600 text-center">
    {currentEvent?.description?.split('\n').map((line, index) => (
      <p key={index} className="mb-2">{line}</p>
    ))}
  </div>
            <div className="w-full max-w-md mt-6 border-t border-gray-300 pt-4">
              <p className="mb-2 text-lg text-gray-600"><strong>Caution à régler: </strong> {(currentEvent?.depositAmount || 0)}€</p>
              <p className="mb-2 text-lg text-gray-600"><strong>Frais de commission: </strong>{(currentEvent?.feeAmount || 0)}€</p>
            </div>
          </div>
          <div className="flex flex-col items-center p-4">
            <form onSubmit={handleSubmit} className="w-full max-w-md">
              <div className="mb-4">
                <input
                  type="text"
                  id="participantName"
                  placeholder="Nom du club ou nom du participant"
                  value={participantName}
                  onChange={(e) => setParticipantName(e.target.value)}
                  required
                  className="w-full p-3 text-lg border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                />
              </div>
              <div className="mb-4">
                <input
                  type="email"
                  id="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="w-full p-3 text-lg border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                />
              </div>
              <div className="mb-4">
                <CardElement options={cardElementOptions} className="p-3 border border-gray-300 rounded-lg bg-gray-50" />
              </div>
              {errorMessage && <div className="mb-4 text-red-600 text-center">{errorMessage}</div>}
              <button type="submit" className="w-full p-4 text-lg font-semibold text-white bg-black rounded-lg hover:bg-customRed disabled:bg-gray-400 disabled:cursor-not-allowed" disabled={!stripe || isProcessing}>
                {isProcessing ? 'Paiement en cours...' : 'Payer'}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
    </div>
  );
};

const PaymentFormWrapper: React.FC = () => (
  <Elements stripe={stripePromise}>
    <PaymentForm />
  </Elements>
);

const PaymentSuccess: React.FC = () => (
  <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
    <div className="p-8 bg-white rounded-lg shadow-lg">
      <h2 className="mb-4 text-2xl font-bold text-green-600">Paiement Réussi!</h2>
      <p className="text-lg text-gray-600">Merci pour votre paiement. Votre participation est confirmée.</p>
    </div>
  </div>
);

const PaymentError: React.FC = () => (
  <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
    <div className="p-8 bg-white rounded-lg shadow-lg">
      <h2 className="mb-4 text-2xl font-bold text-red-600">Échec du Paiement</h2>
      <p className="text-lg text-gray-600">Malheureusement, votre paiement n'a pas pu être traité. Veuillez réessayer.</p>
    </div>
  </div>
);

export { PaymentFormWrapper, PaymentSuccess, PaymentError };


const cardElementOptions: StripeCardElementOptions = {
  hidePostalCode: true,
  style: {
    base: {
      fontSize: '16px', // Match the font size of other input elements
      padding: '20px 12px', // Add padding to match other input elements
      color: '#32325d',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
}