import React, { useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';

const ContactForm: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Initialize EmailJS with your public key
  useEffect(() => {
    emailjs.init('9LGBufY_trYN2UX6w'); // Replace with your EmailJS public key
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const templateParams = {
      from_name: name,
      from_email: email,
      message: message,
    };

    emailjs.send('service_pb7npzd', 'template_ljwq4ng', templateParams)
      .then((response) => {
        console.log('Email sent successfully:', response.status, response.text);
        setSuccessMessage('Votre message a été envoyé avec succès!');
        setErrorMessage(''); // Clear any previous error message
      })
      .catch((error) => {
        console.error('Failed to send email:', error);
        setErrorMessage('L\'envoi du message a échoué, veuillez réessayer.');
        setSuccessMessage(''); // Clear any previous success message
      });
  };

  return (
    <form onSubmit={handleSubmit} className="w-full max-w-2xl mx-auto p-0 rounded-lg pt-12">
      <h2 className="text-2xl font-bold text-gray-800 mb-4 text-center">Formulaire de Contact</h2>
      {successMessage && <p className="text-green-500 mb-4">{successMessage}</p>}
      {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
      <div className="mb-4">
        <label htmlFor="name" className="block text-gray-700">Nom</label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
          required
        />
      </div>
      <div className="mb-4">
        <label htmlFor="email" className="block text-gray-700">Email</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
          required
        />
      </div>
      <div className="mb-4">
        <label htmlFor="message" className="block text-gray-700">Message</label>
        <textarea
          id="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
          required
        />
      </div>
      <button 
        type="submit" 
        className={`w-full p-3 rounded-lg ${successMessage ? 'bg-gray-500' : 'bg-black'} text-white hover:bg-gray-800`}
        disabled={!!successMessage}
      >
        {successMessage ? 'Envoyé' : 'Envoyer'}
      </button>
    </form>
  );
};

export default ContactForm;
