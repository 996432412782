import axios, { AxiosError } from 'axios';

const client = axios.create({
  baseURL: 'https://us-central1-app-tournament-51c87.cloudfunctions.net/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

type Variables = { eventId: string; participantName: string };
type Response = {
  paymentIntentId: string;
  clientSecret: string;
  amount: number;
};

const createPaymentIntent = async (variables: Variables): Promise<Response> => {
  try {
    const response = await client.post<Response>(
      '/events/create-payment-intent',
      variables
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new AxiosError(
        error.message,
        error.code,
        error.config,
        error.request,
        error.response
      );
    } else {
      throw new Error('An unexpected error occurred');
    }
  }
};

export default createPaymentIntent;
