import axios, { AxiosError } from 'axios';
import type { Event } from '@app-tournament/types';

const client = axios.create({
  baseURL: 'https://us-central1-app-tournament-51c87.cloudfunctions.net/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

type Variables = { eventId: string };
type Response = Event;

const fetchEvent = async (variables: Variables): Promise<Response> => {
  try {
    const response = await client.get<Response>(
      `events/event/${variables.eventId}`
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new AxiosError(
        error.message,
        error.code,
        error.config,
        error.request,
        error.response
      );
    } else {
      throw new Error('An unexpected error occurred');
    }
  }
};

export default fetchEvent;
