import React from 'react';
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import {PaymentFormWrapper, PaymentSuccess, PaymentError} from './components/PaymentFormWrapper';
import Footer from './components/Footer';
import LandingPage from './components/LandingPage';
import TermsOfService from './components/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy';
import DeleteUser from './components/DeleteUser';

const Payment = () => {
  return (
    <>
      <PaymentFormWrapper />
      <Footer />
    </>
  );
};

const Success = () => {
  return (
    <>
      <PaymentSuccess />
    </>
  );
};

const Error = () => {
  return (
    <>
      <PaymentError />
    </>
  );
};

const router = createBrowserRouter([
  {
    id: "home",
    path: "/",
    Component: LandingPage,
  },
  {
    id: "payment",
    path: "/payment/:eventId",
    Component: Payment,
  },
  {
    id: "payment-success",
    path: "/payment-success",
    Component: Success,
  },
  {
    id: "payment-error",
    path: "/payment-error",
    Component: Error,
  },
  {
    id: "terms-of-service",
    path: "/cgu",
    Component: TermsOfService,
  },
  {
    id: "privacy-policy",
    path: "/politique-de-confidentialite",
    Component: PrivacyPolicy,
  },
  {
    id: "delete-user",
    path: "/delete-user",
    Component: DeleteUser,
  },
]);

export default function App() {
  return (
    <RouterProvider router={router} fallbackElement={<p>Initial Load...</p>} />
  );
}
