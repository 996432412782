import React from 'react';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <footer className="mt-10 p-5 text-center bg-gray-100 text-gray-700">
      <div className="mt-4 flex justify-center space-x-4">
        <Link to="/cgu" className="text-blue-600 hover:underline">
          Conditions Générales d'Utilisation
        </Link>
        <Link to="/politique-de-confidentialite" className="text-blue-600 hover:underline">
          Politique de confidentialité
        </Link>
      </div>
    </footer>
  );
};

export default Footer;