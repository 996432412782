import React from 'react';

const DeleteUser: React.FC = () => {
  return (
    <div className="container mx-auto px-6 py-8">
      <h1 className="text-3xl font-bold text-gray-800 mb-6">Suppression de Compte</h1>
      <h2 className="text-2xl font-bold text-gray-800 mt-6 mb-4">1. Introduction</h2>
      <p className="text-gray-600 mb-4">
        Si vous souhaitez supprimer votre compte ActivyTime, nous vous invitons à suivre la procédure décrite ci-dessous. La suppression de votre compte entraînera la suppression de toutes vos informations personnelles de notre base de données.
      </p>
      <h2 className="text-2xl font-bold text-gray-800 mt-6 mb-4">2. Procédure de Suppression</h2>
      <p className="text-gray-600 mb-4">
        Pour demander la suppression de votre compte, veuillez nous contacter par téléphone au numéro suivant : <strong>+33 6 19 92 80 28</strong>. Nous traiterons votre demande dans les plus brefs délais.
      </p>
      <h2 className="text-2xl font-bold text-gray-800 mt-6 mb-4">3. Confirmation de la Suppression</h2>
      <p className="text-gray-600 mb-4">
        Une fois votre demande de suppression effectuée, nous vous enverrons une confirmation par e-mail à l'adresse associée à votre compte. La suppression sera irréversible, et toutes vos données seront définitivement effacées.
      </p>
      <h2 className="text-2xl font-bold text-gray-800 mt-6 mb-4">4. Contact</h2>
      <p className="text-gray-600 mb-4">
        Si vous avez des questions concernant la suppression de votre compte ou sur tout autre sujet, veuillez nous contacter au numéro de téléphone mentionné ci-dessus.
      </p>
    </div>
  );
};

export default DeleteUser;