import React from 'react';
import Footer from './Footer';
import ContactForm from './ContactForm';

const LandingPage: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <header className="bg-black shadow-lg">
        <div className="container mx-auto px-6 py-4 flex items-center justify-between">
          <div className="flex items-center">
            <img src="/logo192.png" alt="Activy Event Logo" className="w-12 h-12 mr-4" />
            <h2 className="text-4xl font-bold text-white">Activy Event</h2>          </div>
        </div>
      </header>
      <main className="flex-grow">
        <section className="bg-white py-32">
          <div className="container mx-auto px-6 text-center">
            <h2 className="text-4xl font-bold text-gray-800">La solution pour des événements sans désistements</h2>
            <p className="mt-4 text-lg text-gray-600">Créez, gérez et participez à des événements en toute simplicité.</p>
            <button className="mt-6 px-8 py-3 bg-black text-white text-lg font-semibold rounded-full shadow-md hover:bg-black-300">
                App bientôt disponible
              </button>
          </div>
        </section>
        <section className="py-8">
          <div className="container mx-auto px-6 grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="flex flex-col justify-center">
              <h3 className="text-2xl font-bold text-gray-800">Organisez et participez à des événements en toute simplicité</h3>
              <p className="mt-4 text-gray-600">Avec ACTIVY EVENT, sécurise tes tournois en conditionnant l'inscription par le versement d'un caution dont tu choisiras le montant. Plus de désistement au dernier moment, l'application te permettra de gagner en sécurité et sérénité.</p>
            </div>
            <div>
              <img src="/assets/event-organization.png" alt="Event organization" className="rounded-lg shadow-lg" />
            </div>
          </div>
        </section>
        <section className="bg-white py-8">
          <div className="container mx-auto px-6 grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <img src="/assets/invite-participants.png" alt="Invite participants" className="rounded-lg shadow-lg" />
            </div>
            <div className="flex flex-col justify-center">
              <h3 className="text-2xl font-bold text-gray-800">Invitez des participants et gérez les paiements</h3>
              <p className="mt-4 text-lg text-gray-600">Créez, gérez et participez à des événements en toute simplicité.</p>
            </div>
          </div>
        </section>
        <ContactForm />
      </main>
      <Footer />
    </div>
  );
};

export default LandingPage;
