import React from 'react';

const TermsOfService: React.FC = () => {
  return (
    <div className="container mx-auto px-6 py-8">
      <h1 className="text-3xl font-bold text-gray-800 mb-6">Conditions Générales d'Utilisation et de Vente d’ActivyTime</h1>
      <h2 className="text-2xl font-bold text-gray-800 mt-6 mb-4">Article 1 : Préambule</h2>
      <p className="text-gray-600 mb-4">
        Les présentes Conditions Générales d’Utilisation et de Vente (CGUV) ont pour objet de définir les modalités d’accès, d’utilisation du site internet d’ActivyTime, ainsi que les conditions de participation aux événements organisés par ActivyTime. En naviguant sur le site et en s’inscrivant à un événement, l’utilisateur accepte sans réserve les présentes CGUV.
      </p>
      <h2 className="text-2xl font-bold text-gray-800 mt-6 mb-4">Article 2 : Accès au site</h2>
      <p className="text-gray-600 mb-4">
        Le site est accessible gratuitement à tout utilisateur disposant d’un accès à internet. Tous les coûts relatifs à l’accès au site, que ce soit les frais matériels, logiciels ou d’accès à internet, sont exclusivement à la charge de l’utilisateur.
      </p>
      <h2 className="text-2xl font-bold text-gray-800 mt-6 mb-4">Article 3 : Inscription aux événements</h2>
      <p className="text-gray-600 mb-4">
        La participation aux événements organisés par ActivyTime est soumise à une inscription préalable via notre plateforme en ligne. L’inscription est validée après réception du paiement de la caution, conformément aux modalités décrites ci-dessous.
      </p>
      <h2 className="text-2xl font-bold text-gray-800 mt-6 mb-4">Article 4 : Caution</h2>
      <p className="text-gray-600 mb-4">
        Chaque participant est tenu de verser une caution lors de l’inscription à un événement. Le montant de la caution est précisé sur la page de l’événement. Cette caution n’est pas débitée tant que le participant assiste à l’événement.
      </p>
      <h2 className="text-2xl font-bold text-gray-800 mt-6 mb-4">Article 5 : Débit de la caution</h2>
      <p className="text-gray-600 mb-4">
        Si le participant ne se présente pas à l’événement sans annulation préalable dans les conditions spécifiées, la caution sera débitée sous un délai de 30 jours à compter de la date de l’événement. Dans le cas contraire, la caution ne sera pas débitée.
      </p>
      <h2 className="text-2xl font-bold text-gray-800 mt-6 mb-4">Article 6 : Commission</h2>
      <p className="text-gray-600 mb-4">
        En s’inscrivant à un événement, chaque participant accepte de verser une commission de 1 euro à ActivyTime pour la gestion et l’organisation de l’événement. Cette commission est prélevée indépendamment de la participation effective à l’événement.
      </p>
      <h2 className="text-2xl font-bold text-gray-800 mt-6 mb-4">Article 7 : Annulation</h2>
      <p className="text-gray-600 mb-4">
        Le participant peut annuler sa participation à un événement selon les modalités et délais indiqués lors de l’inscription. En cas d’annulation conforme aux conditions, la caution ne sera pas débitée. Passé le délai d’annulation, la caution sera intégralement débitée.
      </p>
      <h2 className="text-2xl font-bold text-gray-800 mt-6 mb-4">Article 8 : Propriété intellectuelle</h2>
      <p className="text-gray-600 mb-4">
        Le contenu du site, y compris mais sans s’y limiter, les textes, images, graphismes, logos, icônes et sons, est la propriété exclusive d’ActivyTime et est protégé par les lois en vigueur sur la propriété intellectuelle. Toute reproduction, distribution ou utilisation du contenu est strictement interdite sans autorisation préalable d’ActivyTime.
      </p>
      <h2 className="text-2xl font-bold text-gray-800 mt-6 mb-4">Article 9 : Responsabilité</h2>
      <p className="text-gray-600 mb-4">
        ActivyTime décline toute responsabilité en cas d’annulation d’un événement pour des raisons indépendantes de sa volonté (cas de force majeure, conditions climatiques, etc.). En cas d’annulation par ActivyTime, les cautions seront intégralement remboursées. ActivyTime s’efforce d’assurer l’exactitude et la mise à jour des informations publiées sur son site, mais ne saurait être tenu responsable des erreurs ou omissions dans ces informations. ActivyTime décline également toute responsabilité en cas de dysfonctionnement du site ou d’interruption de l’accès au site.
      </p>
      <h2 className="text-2xl font-bold text-gray-800 mt-6 mb-4">Article 10 : Protection des données personnelles</h2>
      <p className="text-gray-600 mb-4">
        Les informations recueillies lors de l’inscription sont nécessaires pour la gestion des inscriptions et le bon déroulement des événements. Elles sont traitées avec confidentialité conformément à notre politique de protection des données personnelles. ActivyTime s’engage à protéger la vie privée des utilisateurs conformément à la réglementation en vigueur.
      </p>
      <h2 className="text-2xl font-bold text-gray-800 mt-6 mb-4">Article 11 : Liens hypertextes</h2>
      <p className="text-gray-600 mb-4">
        Le site peut contenir des liens hypertextes vers d’autres sites internet. ActivyTime n’assume aucune responsabilité quant au contenu de ces sites tiers et décline toute responsabilité en cas de préjudice résultant de l’accès à ces sites.
      </p>
      <h2 className="text-2xl font-bold text-gray-800 mt-6 mb-4">Article 12 : Modification des CGUV</h2>
      <p className="text-gray-600 mb-4">
        ActivyTime se réserve le droit de modifier les présentes CGUV à tout moment. Les participants et utilisateurs seront informés des modifications par tout moyen approprié. Les nouvelles CGUV s’appliqueront immédiatement à toute nouvelle inscription ou utilisation du site.
      </p>
      <h2 className="text-2xl font-bold text-gray-800 mt-6 mb-4">Article 13 : Droit applicable et juridiction compétente</h2>
      <p className="text-gray-600 mb-4">
        Les présentes CGUV sont régies par le droit français. Tout litige relatif à leur interprétation et/ou exécution relève des tribunaux compétents du ressort de la Cour d’appel de Paris.
      </p>
    </div>
  );
};

export default TermsOfService;