import React from 'react';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="container mx-auto px-6 py-8">
      <h1 className="text-3xl font-bold text-gray-800 mb-6">Politique de Confidentialité</h1>
      <h2 className="text-2xl font-bold text-gray-800 mt-6 mb-4">1. Introduction</h2>
      <p className="text-gray-600 mb-4">
        Chez ActivyTime, nous prenons la confidentialité de vos données très au sérieux. Cette politique de confidentialité explique quelles informations nous collectons, comment nous les utilisons et les mesures que nous prenons pour les protéger.
      </p>
      <h2 className="text-2xl font-bold text-gray-800 mt-6 mb-4">2. Collecte des informations</h2>
      <p className="text-gray-600 mb-4">
        Nous collectons des informations lorsque vous vous inscrivez sur notre site, participez à un événement ou interagissez avec nos services. Les informations collectées peuvent inclure votre nom, adresse e-mail, numéro de téléphone, et toute autre information nécessaire à la gestion des événements.
      </p>
      <h2 className="text-2xl font-bold text-gray-800 mt-6 mb-4">3. Utilisation des informations</h2>
      <p className="text-gray-600 mb-4">
        Les informations collectées sont utilisées pour :
        <ul className="list-disc list-inside">
          <li>Gérer vos inscriptions et participations aux événements</li>
          <li>Améliorer notre site web et nos services</li>
          <li>Vous envoyer des informations importantes concernant les événements</li>
          <li>Répondre à vos questions et demandes de support</li>
        </ul>
      </p>
      <h2 className="text-2xl font-bold text-gray-800 mt-6 mb-4">4. Protection des informations</h2>
      <p className="text-gray-600 mb-4">
        Nous mettons en œuvre diverses mesures de sécurité pour protéger vos informations personnelles. Vos données sont stockées sur des serveurs sécurisés et nous limitons l'accès à ces informations aux seuls employés et partenaires qui en ont besoin pour fournir nos services.
      </p>
      <h2 className="text-2xl font-bold text-gray-800 mt-6 mb-4">5. Partage des informations</h2>
      <p className="text-gray-600 mb-4">
        Nous ne vendons, n'échangeons ni ne transférons vos informations personnelles à des tiers sans votre consentement, sauf si cela est nécessaire pour fournir nos services ou si la loi l'exige.
      </p>
      <h2 className="text-2xl font-bold text-gray-800 mt-6 mb-4">6. Cookies</h2>
      <p className="text-gray-600 mb-4">
        Notre site utilise des cookies pour améliorer votre expérience utilisateur. Les cookies sont de petits fichiers stockés sur votre appareil qui nous aident à analyser l'utilisation du site et à personnaliser notre contenu.
      </p>
      <h2 className="text-2xl font-bold text-gray-800 mt-6 mb-4">7. Vos droits</h2>
      <p className="text-gray-600 mb-4">
        Vous avez le droit d'accéder à vos informations personnelles, de les corriger, de les supprimer ou de limiter leur traitement. Pour exercer ces droits, veuillez nous contacter à l'adresse e-mail fournie sur notre site.
      </p>
      <h2 className="text-2xl font-bold text-gray-800 mt-6 mb-4">8. Modifications de la politique de confidentialité</h2>
      <p className="text-gray-600 mb-4">
        Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Les modifications seront publiées sur cette page et prendront effet immédiatement. Nous vous encourageons à consulter régulièrement cette page pour rester informé des mises à jour.
      </p>
      <h2 className="text-2xl font-bold text-gray-800 mt-6 mb-4">9. Contact</h2>
      <p className="text-gray-600 mb-4">
        Si vous avez des questions concernant cette politique de confidentialité, veuillez nous contacter au numéro de téléphone: +33 6 19 92 80 28.
      </p>
    </div>
  );
};

export default PrivacyPolicy;