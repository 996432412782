import axios, { AxiosError } from 'axios';

const client = axios.create({
  baseURL: 'https://us-central1-app-tournament-51c87.cloudfunctions.net/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

type Variables = {
  eventId: string;
  paymentIntentId: string;
  email: string;
  participantName: string;
};

type Response = string;

const confirmPayment = async (variables: Variables): Promise<Response> => {
  try {
    const response = await client.post<Response>(
      '/events/confirm-payment',
      variables
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new AxiosError(
        error.message,
        error.code,
        error.config,
        error.request,
        error.response
      );
    } else {
      throw new Error('An unexpected error occurred');
    }
  }
};

export default confirmPayment;
